/*
 * @Author: lmk
 * @Date: 2021-07-08 13:15:26
 * @LastEditTime: 2022-08-20 17:28:43
 * @LastEditors: lmk
 * @Description: common style
 */
/*var*/
$themeBg:#F8F8F8;
//normallize
p{
  margin: 0;
  padding: 0;
  word-break: break-all;
  line-height:19px;
}
/*padding margin style*/
$mpType:padding margin;
$mpDirection:left top bottom right;
$mpSize:3 5 7 8 10 11 12 13 15 18 19 20 24 25 40;
$mpSecondDirection:tb lr;
@each $mt in $mpType {
  //padding margin 
  @each $ms in $mpSize {
    .m-#{$mt}#{$ms}{
      #{$mt}: #{$ms}px;
    }
  }
  //padding&&margintop/left/right/bottom
  @each $md in $mpDirection {
    @each $ms in $mpSize {
      .m-#{$mt}-#{$md}#{$ms}{
        #{$mt}-#{$md}: #{$ms}px;
      }
    }
  }
  //padding&&marginlr/tb
  @each $msd in $mpSecondDirection {
    @each $ms in $mpSize {
      .m-#{$mt}-#{$msd}#{$ms}{
        @if $msd=='tb' {
          #{$mt}-top:#{$ms}px;
          #{$mt}-bottom:#{$ms}px;
        }
        @if $msd=='lr' {
          #{$mt}-left:#{$ms}px;
          #{$mt}-right:#{$ms}px;
        }
      }
    }
  }
}
/*position style*/
$positon:relative fixed absolute;
@each $var in $positon {
	.m-position-#{$var}{
		position: #{$var};
	}
}

/*colors style*/
$positon:fff eee f8f8f8 999 FF3D62 333 666 5c65f6 555 111; 
@each $var in $positon {
	.m-colors-#{$var}{
    $unquote:'#';
		color: #{$unquote}#{$var};
	}
  .m-bg-#{$var}{
    $unquote:'#';
		background-color: #{$unquote}#{$var};
	}
}
/*mobile 1px line*/
@mixin line($color){
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  transform: scaleY(.5);
  background-color: $color;
}
@mixin lineStyle($position,$color){
  position: relative;
  @if $position=='bottom' {
    &::after{
      @include line($color);
      bottom: 0;
    }
  }
  @if $position=='top' {
    &::before{
      @include line($color);
      top: 0;
    }
  }
}
@mixin lineClass($position,$color){
  &-#{$position}{
    @include lineStyle($position,$color)
  }
}
.m-line{
  @include lineClass(bottom,#EEEEEE );
  @include lineClass(top,#EEEEEE )
}
/*flex*/
.m-flex{
  display: flex;
  align-items: center;
  &.m-col{
    flex-direction: column;
  }
  &.m-row{
    &-between{
      justify-content: space-between;
    }
    &-right{
      justify-content: right;
    }
    &-center{
      justify-content: center;
    }
    &-end{
      justify-content: flex-end;
    }
  }
  &-1{
    flex: 1;
  }
  &-col{
    align-items:inherit;
    flex-direction: column;
  }
  &.m-col{
    &-top{
      align-items:flex-start;
    }
  }
  &-warp{
    flex-wrap: wrap;
  }
}
/*font size*/
$size:12 13 14 15 16 17 18 20;
@each $var in $size {
	.m-font#{$var}{
		font-size: #{$var}px;
	}
}
/*border radius*/
.border{
  &-circle{
    border-radius: 50%;
  }
  &-square{
    border-radius: 1px;
  }
  &-radius5{
    border-radius: 5px;
  }
}
/* text align*/
$align:center left right;
@each $var in $align {
	.m-text-#{$var}{
		text-align: #{$var};
	}
}
/**
 * @description: common className
 */
 @mixin title($size) {
  font-weight: bold;
  font-size: $size+px;
 }
.m-title{
  @include title(20)
}

.Panel .m-layout{
  height: calc(100vh - 60px - var(--window-layout-height))
}
.m-layout{
  overflow-y: auto;
  height: calc(100vh - var(--window-layout-height) - 45px)
}
body{
  .za-nav-bar{
    background: white;
    @include lineStyle(bottom,#EEEEEE );
    z-index: 55;
  }
}
.page{
  height: calc(100vh - var(--window-layout-height));
}
.item-eli{
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.input{
  font-size: 15px;
}
//grid布局
//设置grid的模块高度 列分为几分 宽度 行分为几份
@mixin m-grid($height:100vh,$colSize:3,$width:100vw,$rowSize:3,$gutter:0){
  grid-template-columns: repeat($rowSize,$width / $rowSize);
  grid-gap: $gutter;
}
.m-grid{
  display: grid;
  box-sizing: border-box;
  align-items: center;
  justify-items: center;
}
.za-tabs--horizontal .za-tabs__line{
  height: 4px;
  margin-bottom: 2px;
  .za-tabs__line__inner{
    border-radius: 10px;
  }
}
.active{
  font-size: 19px;
  font-weight: bolder;
  transition: all .2s;
  color: #333333;
}
.unactive{
  color: #666666;
  font-size: 15px;
}
.za-badge--circle .za-badge__content{
  font-size: 11px;
}
.za-confirm__button{
  width: calc(50% - 5px);
  height: 44px;
  background: #EEEEEE;
  border-radius: 22px;
  font-weight:600;
  color: #666666;
  &.za-confirm__button--ok{
    background-color: var(--theme-primary);
    color: white;
  }
  &::after,&:not(:first-of-type):after{
    width: 0;
    height: 0;
  }
}
.za-confirm .za-modal__footer{
  padding: 20px 20px 30px;
  display: flex;
  justify-content: space-between;
}
.za-modal__header{
  padding-top: 30px;
  font-weight:bold;
}
.za-modal__body{
  font-size: 17px;
  color: #666;
  padding: 25px 25px 20px;
}
.za-modal--radius .za-popup{
  border-radius: 15px;
}
.pull-empty{
  line-height: 40px;
  color: #666;
  font-size: 14px;
  text-align: center;
}
.hidden{
  display: none;
}

.adm-tabs-header{
  background-color: white;
}
.adm-tabs-tab-list{
  height: 50px;
  .adm-tabs-tab-wrapper-stretch{
    flex: 1;
  }
}
.adm-form{
  .adm-form .adm-list.adm-list{
    --border-inner: 1px solid #EEEEEE;
  }
  .adm-list-default .adm-list-body{
    border: none;
  }
  .adm-list-item{
    padding-left: 0;
  }
  .adm-list-item-content-main{
    padding: 15px 0;
  }
  .adm-list-item-content-arrow{
    font-size: 11px;
    color: #cdcdcc;
    font-weight: bold;
    margin-left: 15px;
  }
  .adm-list-item-content{
    padding-left:15px ;
    padding-right: 29px;
  }
}


.user-bg{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.app-container{
  position: relative;
  // min-height: 100vh;
  // z-index: 11111;
  height: calc(100vh - var(--window-layout-height));
  overflow-y: auto;
}
.nav-icon{
  width: 30px;
  height: 30px;
}
@mixin headerBgView {
  position: relative;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}
.nav-box{
  @include headerBgView;
  height: 60px;
}
.userinfo-box{
  @include headerBgView;
  padding: 20px 15px;
  box-sizing: border-box;
}
.avatar-box{
  border-radius: 50%;
  border: 2px solid white;
  width: 79px;
  height: 79px;
}
.user-info{
  margin-left:13px;
}
.username{
  font-size: 22px;
  color: white;
  font-weight: bold;
}
.follow-box{
  margin-top: 35px;
  font-size: 14px;
  .follow-name{
    color: #666;
  }
  .follow-num{
    color: #333;
    font-weight: bold;
    margin-right: 7px;
  }
  div{
    &:first-child{
      margin-right: 22px;
    }
  }
}
.active{
  font-size: 19px;
  font-weight: bolder;
  transition: all .2s;
  color: #333333;
}
.unactive{
  color: #666666;
  font-size: 15px;
}
.za-tabs{
  .za-tabs__header{
    background-color: white;
  }
}
.user-post-content{
  .m-layout{
    height: auto;
    min-height: 400px;
    overflow: hidden;
  }
}
.user-follow-page{
  .m-layout{
    height: calc(100vh - 90px - var(--window-layout-height));
  }
}
.user-detail-header{
  // background: url(~/static/images/user_bg.jpg) no-repeat;
  background-position: top;
  background-size: 100vw 115px;
  background-repeat: no-repeat;
  background-color: white;
}
.intro-content{
  background: #F8F8F8;
  border-radius: 5px;
  padding: 15px 26px;
  margin:  0 15px;
  position: relative;
}
.intro-txt{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
}
.quotes{
  width: 8px;
  position: absolute;
  &.left{
    top: 11px;
    left: 7px;
  }
  &.right{
    bottom: 11px;
    right: 7px;
  }
}
.NFT-list{
  padding:0 15px 15px;
  .NFT-title{
    font-size: 19px;
    font-weight: bold;
    color: #333333;
    margin-top: 4px;
  }
}
.NFT-empty-line{
  height: 15px;
}
.nft-list-items{
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  margin-top: 18px;
  .grid-item{
    img{
      width: 100%;
      height: calc((100vw - 60px) / 3);
    }
  }
}
.nft-name{
  margin-top: 7px;
  padding-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  width: calc((100vw - 60px) / 3);
}
.view-more{
  width: 105px;
  margin: 5px auto 0;
  padding-bottom: 20px;
  span{
    font-size: 12px;
  }
  img{
    width:4px;
    margin-left: 5px;
  }
}
.tab-header{
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  .adm-tabs-tab-active{
    font-size: 19px;
    font-weight: bold;
    color: #333333;
  }
}
.tab-content{
  margin-top: 10px;
}
.adm-tabs{
  --active-line-height: 4px;
}