body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root:root {
  --adm-color-primary:#5D61FF;
  --adm-border-color:#EEEEEE;
  --active-line-height:1px;
  --fixed-active-line-width:9px;
  --adm-color-text-secondary: #333;
  
}
div.adm-nav-bar{
  --border-bottom: 1px solid #eee
}